import * as React from 'react';
import '../core/polyfills';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Navigation } from '../components/navigation/navigation.component';
import { Blocks } from '../components/blocks/blocks';
import { Footer } from '../components/footer/footer.component';
import '../scss/styles.scss';
import lozad from 'lozad';
export default class extends React.Component<
  GatsbyProps<ContentfulProps<Page>>
> {
  public observer: { observe: () => void; observer: IntersectionObserver };
  componentDidMount() {
    // IE
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      console.log('is IE');
      window.location.replace('/ie');
    }
    // Lazy
    const el = document.querySelectorAll('.lazy');
    this.observer = lozad(el, {
      rootMargin: '420px 0px',
    });
    this.observer.observe();
  }

  componentWillUnmount() {
    this.observer.observer.disconnect();
  }

  render() {
    const {
      title,
      slug,
      metaTitle,
      description,
      metaImage,
      environment,
      className,
      blocks,
    } = this.props.data.contentfulPage;
    const realSlug = slug === 'home' ? '' : slug;
    return (
      <div className={`page ${slug} ${className}`}>
        <Helmet>
          <html lang="en" />
          <title>{metaTitle || 'Norsepower Rotor Sails'}</title>

          <script>
            {
              "console.log('%c B O U  ', 'padding: 20px 12px; font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');console.log('%c https://bou.co', 'padding: 12px 42px;')"
            }
          </script>

          <meta
            name="description"
            content={
              description
                ? description.description
                : environment.metaDescription.metaDescription
            }
          />
          <meta name="robots" content="index, follow" />
          <link
            rel="canonical"
            href={`https://www.norsepower.com/${
              realSlug ? realSlug + '/' : ''
            }`}
          />
          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Norsepower",
              "url": "https://www.norsepower.com/",
              "logo": "https:${environment.logo.file.url}?fm=jpg"
            }`}
          </script>

          <link rel="preconnect" href="https://www.googletagmanager.com" />

          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-56FK6Z2');`}
          </script>

          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`https://www.norsepower.com/${realSlug}`}
          />
          <meta
            property="og:title"
            content={metaTitle || 'Norsepower Rotor Sails'}
          />
          <meta
            property="og:description"
            content={
              description
                ? description.description
                : environment.metaDescription.metaDescription
            }
          />
          <meta
            property="og:image"
            content={`https:${
              metaImage ? metaImage.file.url : environment.metaImage.file.url
            }`}
          />
          <meta
            property="og:image:alt"
            content={metaTitle || 'Norsepower Rotor Sails'}
          />
          <meta property="og:image:type" content="image/jpg" />
          <meta property="og:image:width" content="1920" />
          <meta property="og:image:height" content="1080" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@Norsepowerltd" />
        </Helmet>

        <Navigation
          config={environment.navigation}
          logo={environment.logo}
          logoAlternative={environment.logoAlternative}
        />
        <main>
          <Blocks blocks={blocks} />
        </main>
        <Footer
          config={environment.footer}
          logo={environment.logoAlternative}
        />

        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-56FK6Z2"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      className
      metaTitle
      description {
        description
      }
      metaImage {
        file {
          url
        }
      }
      environment {
        metaDescription {
          metaDescription
        }
        metaImage {
          file {
            url
          }
        }
        logo {
          file {
            url
          }
        }
        logoAlternative {
          file {
            url
          }
        }
        navigation {
          links {
            title
            path
            children {
              title
              path
            }
          }
        }
        footer {
          left {
            path
            title
          }
          info {
            email
            address
          }
          right {
            path
            href
            title
          }
        }
      }
      blocks {
        ... on Node {
          internal {
            type
          }
        }
        ... on ContentfulBlogPostsContainer {
          config {
            highlight
            loadMorePosts: loadMore
            amount
          }
        }
        ... on ContentfulMarkdownBlock {
          className
          body {
            body
          }
        }
        ... on ContentfulQuoteBlock {
          cite
          citeTitle
          body {
            body
          }
        }
        ... on ContentfulImageBlock {
          className
          altText
          image {
            file {
              url
            }
          }
        }
        ... on ContentfulExpansionPanelBlock {
          className
          config {
            loadMore
            left
            right
            items {
              title
              suffix
              description
            }
          }
        }
        ... on ContentfulVideoEmbeddedBlock {
          title
          src
          className
          config {
            autoplay
            loop
            controls
          }
          video {
            file {
              url
            }
          }
          placeholderImage {
            file {
              url
            }
          }
        }
        ... on ContentfulLogosBlock {
          className
          logos {
            description
            file {
              url
            }
          }
        }
        ... on ContentfulContainer {
          title
          layout
          className
          config {
            scrollCarousel
            showTitle
          }
          blocks {
            ... on Node {
              internal {
                type
              }
            }
            ... on ContentfulMarkdownBlock {
              className
              body {
                body
              }
            }
            ... on ContentfulImageBlock {
              className
              altText
              image {
                file {
                  url
                }
              }
            }
            ... on ContentfulShowcaseBlock {
              image {
                file {
                  url
                }
              }
              config {
                path
                shipName
              }
              body {
                body
              }
            }
            ... on ContentfulPersonBlock {
              image {
                file {
                  url
                }
              }
              email
              name
              phone
              title
              description {
                description
              }
            }
            ... on ContentfulVideoEmbeddedBlock {
              title
              src
              config {
                autoplay
                loop
                controls
              }
              video {
                file {
                  url
                }
              }
            }
            ... on ContentfulExpansionPanelBlock {
              className
              config {
                randomOrder
                loadMore
                left
                items {
                  title
                  suffix
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;
